<template>
  <Card>
    <template slot="title">
      <div style="overflow:hidden;">
        <h3
          class="name"
          style="float:left"
        >{{data.qbsAccountName}}
          <img
            style="width:20px;height:auto;vertical-align: middle;"
            src="../../../../assets/images/icon/male.png"
            v-if="data.gender === '1'"
          >
          <img
            style="width:20px;height:auto;vertical-align: middle;"
            src="../../../../assets/images/icon/girl.png"
            v-if="data.gender === '0'"
          >
        </h3>
        <span
          class="tag"
          style="float:right;border:0;font-size:18px"
        >{{handlestatus(data.status)}}</span>
      </div>
    </template>
    <!-- <template slot="extra" >
      <span class="tag" style="float:right">短期无意向</span>
    </template> -->
    <CardCell :label="data.qbsAccountPhone" :colon="false" />
    <h4></h4>
    <CardCell
      label="最近跟进"
      :value='handletime(data.updatetime)'
    />
    <template slot="footer">
      <span class="msg">{{data.ownerUserName}}</span>
      <div
        span="4"
        class="rowWarp"
      >
        <a :href="`tel:${data.qbsAccountPhone}`">
          <Icon
            name="phone-circle-o"
            size="30"
            color='#DA3738'
            class="iconWrap"
          />
        </a>
        <Button
          round
          type="primary"
          size="small"
          style="width: 80px;margin-left:10px"
          @click="handlefollowup(data)"
        >跟进</Button>
      </div>
    </template>
  </Card>
</template>
<script>
import { Button, Icon, Toast } from 'vant'
import { orgCode } from '@/api/chargequery'
import Card from '_c/card'
import 'vant/lib/icon/style'
import Vue from 'vue'
import CardCell from '_c/card/cardCell'
import dayjs from 'dayjs'
Vue.use(Icon)
export default {
  //接收传值
  props: {
    data: Object,
  },
  methods: {
    // 点击事件
    handlefollowup (Info) {
      this.$router.push({
        path: '/businessOpportunitiesFollowUpFrom',
        query: { eosOppId: Info.eosOppId }
      })
    },
    // 处理时间
    handletime (time) {
      const istime = time ? dayjs(time).format('YYYY/MM/DD') : ''
      return istime
    },
    // 点击电话按钮
    handlephine (cardinfo) {
      this.callphone = cardinfo.qbsAccountPhone
      this.orgCode()
    },
    // 电话呼叫
    async orgCode () {
      const res = await orgCode({ orgCode: this.callphone }) //
      if (res.code === '000000') {
        Toast('正在拨打')
      } else {
        Toast(res.msg)
      }
    },
    // 处理状态
    handlestatus (status) {
      const baseData = this.$storage.get('baseData')
      this.oppstatus = baseData.opp_status
      this.oppstatus.forEach(item => {
        if (item.value === status) {
          status = item.key
        }
      })
      return status
    }
  },
  components: {
    Button,
    Card,
    CardCell,
    Icon
  }
}
</script>
<style lang='less' scoped>
.tag {
  display: inline-block;
  padding: 0 10px;
  border: 1px solid;
  border-radius: 8px;
  color: @blue;
  font-size: 12px;
  height: 42px;
  line-height: 42px;
}
.rowWarp {
  display: flex;
  justify-content: space-around;
  align-items: center;
  .iconWrap {
    border-radius: 100%;
    padding: 12px 0px;
    display: flex;
    font-size: 40px;
  }
}
.msg {
  position: relative;
  top: 26px;
  font-size: 24px;
}
</style>