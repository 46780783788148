<!--
 * @Author: your name
 * @Date: 2021-05-20 17:51:30
 * @LastEditTime: 2021-06-07 11:46:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \04eos-app\src\pages\taoGuestPoolLongTerm\index.vue
-->
<template>
  <App
    title="淘客池"
    :border="false"
    left-arrow
    @click-left="onNavBarClickLeft"
  >
    <div slot="extra">
      <Tabs v-model="active">
        <Tab title="Long Term"></Tab>
      </Tabs>
    </div>
    <List
      ref="list"
      :dataList="dataList"
      @on-load="onLoad"
    />
  </App>
</template>
<script>
import { Tabs, Tab, Toast } from 'vant'
import List from './components/list'
import { taokePollopp } from '@/api/chargequery'
export default {
  name: 'taoGuestPoolLongTerm',
  data () {
    return {
      datanew: 'false',
      active: 0, // 0: 全部 1: 门店 2: 城市
      dataList: []
    }
  },
  components: {
    Tabs,
    Tab,
    List
  },
  methods: {
    async getData () {
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      })
      const { data, code } = await taokePollopp({
        page: 1,
        size: 10
      })
      Toast.clear()
      if (code === "000000") {
        this.dataList = data.records
      } else {
        Toast('请求数据失败')
      }

    },
    onLoad () {
      // todo
      this.isLoading = true
      this.getData().then(() => {
        this.$refs.list.list.loading = true
        this.isLoading = false
      })
    },
    onNavBarClickLeft () {
      this.$router.go(-1)
    }
  },
  mounted () {
    this.getData()
  }
}
</script>